import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ViewDetailsModal from './ViewDetailsModal'
import OrdersList from './OrdersList';

export default function Orders() {

    const [orders, setOrders] = useState("")
    const [notice, setNotice] = useState("")
    const [modal, setModal] = useState("")

    const value = localStorage.getItem("https://test7777.ml/orders?totalOrders");

    useEffect(() => {
        setTimeout(() => {

            if (value === "" | value === null | value === undefined) {
                setNotice(<p className='ms-2 mh-100'>No orders yet. Please order your next breakfast and we will be happy to get it prepared for you. If you have previously ordered but it is not being shown in this page then please contact us <Link to="/contact">here</Link> in order to get the order details.</p>)
            } else {
                setNotice(<><h6 className='ms-2'>Note: Your orders are stored locally on your device. Contact us <Link to="/contact" >here</Link> if you need to get information about all your orders in detail.</h6> <hr /></>)
                setOrders(<OrdersList value={value} setModal={(value) => setModal(value)} ViewDetails={(order) => ViewDetails(setModal, order)} />)
            }
        }, 1);
    }, [value])
    
    return (
        <>
            {modal}
            {notice}
            {orders}
        </>
    )
}

function ViewDetails(setModal, order) {
    setModal(<ViewDetailsModal show={true} onHide={() => setModal()} order={order} />)
}
