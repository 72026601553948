import React from 'react'
import { Link } from 'react-router-dom'

export default function Other() {
    return (
        <>
            <hr className="mx-1"/>
            <h4 className="display-6 text-center py-4">Get your <mark>yummy</mark> breakfast delivered to your doorstep <small className="text-muted">or pick it up anytime</small></h4>

            <Link to="/order" className='btn btn-success d-grid gap-2 col-11 mx-auto btn-lg' type="button">Order Your Next Breakfast Now</Link>
        </>
    )
}
