import React from 'react'

export function ExistingUserGreeting(name) {
    return <h4 className='display-6 me-2 ms-sm-2'>{"Welcome " + name}</h4>
}

export function NewUserGreeting() {
    return (
        <form className="form-inline my-2 my-lg-0">
            <button className="btn btn-outline-success my-2 my-sm-0 ms-2" type="button" data-bs-toggle="modal" data-bs-target="#login_SignUpModal">Login / Create account</button>
        </form>
    );
}
