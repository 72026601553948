import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { getDatabase, ref, onValue } from 'firebase/database';

export default function Areas() {

  return (
    <div className='m-4'>
      <h4>Areas we Deliver</h4>

      <p>Currently we deliver in two areas as follows:</p>

      <Row className="mt-4">
        <Col className="md-4">Kidwai Nagar</Col>
        <Button onClick={() => viewDeliveryCharge("Kidwai Nagar")} as={Col} variant="success">View Delivery Charge</Button>
      </Row>

      <Row className='mt-4'>
        <Col className="md-4">Baradevi</Col>
        <Button onClick={() => viewDeliveryCharge("Baradevi")} as={Col} variant="success">View Delivery Charge</Button>
      </Row>
    </div>
  );
}

function viewDeliveryCharge(locality) {
  const db = getDatabase();
  const deliveryChargeRef = ref(db, 'FoodOrder/DeliveryCharge/' + locality);
  onValue(deliveryChargeRef, (snapshot) => {
    const data = snapshot.val();
    alert("The current delivery charge in " + locality + " is ₹" + data)
  });
}
