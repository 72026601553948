import React from 'react'
import { Modal, Button, ListGroup } from 'react-bootstrap'
import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth } from 'firebase/auth'

export default function ViewDetailsModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Order Details
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h5>Order ID: {props.order.orderId}</h5>
                <ListGroup>
                    <ListGroup.Item>
                        <h6>Item Name : </h6> {props.order.itemName}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h6>Order Date : </h6> {props.order.orderDate}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h6>Cooking Instructions : </h6> {props.order.cookingInstructions!=="" ? props.order.cookingInstructions : "No cooking instructions"}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h6>Quantity : </h6> {props.order.quantity}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h6>Address : </h6> {props.order.address}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h6>Price Details : </h6>
                        <ListGroup>
                            <ListGroup.Item>
                                <h6>Item Total : {props.order.itemTotal}</h6>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <h6>Delivery Charge : {props.order.deliveryCharge}</h6>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <h5>Total Bill : {props.order.grandTotal}</h5>
                            </ListGroup.Item>
                        </ListGroup>
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="success" onClick={() => trackOrder(props.order.orderId)}>Track Order</Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>

        </Modal>
    )
}

function trackOrder(id) {
    const db = getDatabase();
    const statusRef = ref(db, 'FoodOrder/orderStatus/' + getAuth().currentUser.uid + "/" + id);
    onValue(statusRef, (snapshot) => {
        const data = snapshot.val();
        alert(data)
    });
}
