import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Button, Row, Col, ButtonGroup, Alert, InputGroup, FormControl, Modal } from 'react-bootstrap'
import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth } from 'firebase/auth'
import CartOrderedModal from './CartOrderedModal';

export default function CartDetails(props) {

    const [quantity, setQuantity] = useState(1)
    const [alert, setAlert] = useState()
    const [cookingInstruction, setCookingInstruction] = useState(false)
    const [deliveryCharge, setDeliveryCharge] = useState("Please Choose Locality")
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [locality, setLocality] = useState("")

    const schema = yup.object().shape({
        address1: yup.string().trim().required("Please enter your address"),
        address2: yup.string(),
        pincode: yup.number().positive("Invalid PIN Code").moreThan(99999, "Invalid PIN Code").lessThan(10000000, "Invalid PIN Code").max(999999, "Invalid PIN Code").min(100000, "Invalid PIN Code"),
        cookingInstructions: yup.string().max(100, "Please enter Cooking Instructions within 100 words")
    })

    return (
        <>
            {alert}

            <Formik
                validationSchema={schema}
                onSubmit={(value) => placeOrder(value)}
                initialValues={{
                    address1: '',
                    address2: '',
                    pincode: '',
                    cookingInstructions: ''
                }}
            >

                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                    <Form className="m-4" noValidate onSubmit={handleSubmit}>

                        <Row>
                            <Col><h4>{props.name}</h4></Col>

                            <Col className="d-none d-md-block"></Col>
                            <Col className="d-none d-lg-block"></Col>

                            <ButtonGroup as={Col} className="mb-2">
                                <Button variant='success' onClick={() => {
                                    if (quantity >= 1) {
                                        if (quantity < props.maxQuantity) {
                                            setQuantity(quantity + 1)
                                        } else {
                                            showAlert("warning", <><Alert.Heading>Maximum Quantity Limit Reached</Alert.Heading>  </>, "Ok", setAlert)
                                            if (quantity > props.maxQuantity) {
                                                setQuantity(props.maxQuantity)
                                            }
                                        }
                                    } else setQuantity(1)

                                }}>+</Button>
                                <Button variant='success' disabled >{quantity}</Button>
                                <Button variant='success' onClick={() => {
                                    if (quantity >= 1) {
                                        if (quantity <= props.maxQuantity) {
                                            if (quantity !== 1) {
                                                setQuantity(quantity - 1)
                                            } else {
                                                showAlert("warning", <><Alert.Heading>Minimum Quantity Limit Reached</Alert.Heading>  </>, "Ok", setAlert)
                                            }
                                        } else {
                                            if (quantity > props.maxQuantity) {
                                                showAlert("warning", <><Alert.Heading>Maximum Quantity Limit Reached</Alert.Heading>  </>, "Ok", setAlert)
                                                setQuantity(props.maxQuantity)
                                            }
                                        }
                                    } else setQuantity(1)
                                }}>-</Button>
                            </ButtonGroup>
                        </Row>

                        <Row>
                            <Col><p>{props.price}</p></Col>

                            <Col className="d-none d-md-block"></Col>
                            <Col className="d-none d-lg-block"></Col>

                            <Col><p>{"₹" + (quantity * props.intPrice)}</p></Col>
                        </Row>

                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Add Cooking Instructions?" onChange={() => setCookingInstruction(!cookingInstruction)} />
                        </Form.Group>

                        <InputGroup hidden={!cookingInstruction}>
                            <InputGroup.Text>Cooking Instructions</InputGroup.Text>
                            <FormControl as="textarea" name="cookingInstructions" onChange={handleChange} onBlur={handleBlur} isValid={values.cookingInstructions && touched.cookingInstructions && !errors.cookingInstructions} value={values.cookingInstructions} placeholder="Maximum 100 letters" aria-label="Cooking Instructions" maxLength={100} />
                            {errors.cookingInstructions && touched.cookingInstructions && values.cookingInstructions ? (<div className='text-danger'>{errors.cookingInstructions}</div>) : null}
                        </InputGroup>

                        <hr />

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder={getAuth().currentUser.displayName} readOnly />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder={getAuth().currentUser.email} readOnly />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control type="tel" placeholder={getAuth().currentUser.phoneNumber} readOnly />
                            </Form.Group>
                        </Row>

                        <hr />

                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label>Address</Form.Label>
                            <Form.Control name="address1" placeholder="1234 Main Street" onChange={handleChange} onBlur={handleBlur} isValid={touched.address1 && !errors.address1} value={values.address1} required />
                            {errors.address1 && touched.address1 ? (<div className='text-danger'>{errors.address1}</div>) : null}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formGridAddress2">
                            <Form.Label>Address 2</Form.Label>
                            <Form.Control name="address2" placeholder="Apartment, studio, or floor" onChange={handleChange} onBlur={handleBlur} isValid={touched.address2 && !errors.address2 && values.address2} value={values.address2} />
                        </Form.Group>

                        <Row className="mb-3">

                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Locality</Form.Label>
                                <Form.Select onChange={e => {
                                    if (e.target.value !== "Choose") {
                                        const db = getDatabase();
                                        const deliveryChargeRef = ref(db, 'FoodOrder/DeliveryCharge/' + e.target.value);
                                        onValue(deliveryChargeRef, (snapshot) => {
                                            const data = snapshot.val();
                                            setDeliveryCharge("₹" + data)
                                            setLocality(e.target.value)
                                        });
                                    } else {
                                        setDeliveryCharge("Please Choose Locality")
                                    }
                                }} defaultValue="Choose">
                                    <option value="Choose">Choose</option>
                                    <option value="Kidwai Nagar">Kidwai Nagar</option>
                                    <option value="Baradevi">Baradevi</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>City</Form.Label>
                                <Form.Control placeholder='Kanpur' readOnly />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridZip">
                                <Form.Label>PIN Code</Form.Label>
                                <Form.Control name="pincode" type="number" onChange={handleChange} onBlur={handleBlur} isValid={values.pincode && touched.pincode && !errors.pincode} value={values.pincode} />
                                {errors.pincode && touched.pincode && values.pincode ? (<div className='text-danger'>{errors.pincode}</div>) : null}
                                {values.pincode === 0 ? <div className='text-danger'>Invalid PIN Code</div> : null}
                            </Form.Group>
                        </Row>

                        <hr />

                        <Row>
                            <Col><h4>Item Total</h4></Col>
                            <Col><h4 >{"₹" + quantity * props.intPrice}</h4></Col>
                        </Row>

                        <Row>
                            <Col><h4>Delivery Charge</h4></Col>
                            <Col><h4>{deliveryCharge}</h4></Col>
                        </Row>

                        <Row>
                            <Col><h2>Grand Total</h2></Col>
                            <Col><h2>{deliveryCharge === "Please Choose Locality" ? "Please Choose Locality" : "₹" + (quantity * props.intPrice + parseInt(deliveryCharge.replace("₹", "")))}</h2></Col>
                        </Row>

                        <hr className='mb-4 mt-4' />

                        <Button variant="success" type="submit" disabled={submitDisabled}>
                            Place Order
                        </Button>
                    </Form>
                )}
            </Formik>

            <Modal
                show={showAlertModal}
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Alert show={true} variant="warning" >

                    <Alert.Heading>Please Choose Locality</Alert.Heading>
                    <Button variant="warning" onClick={() => setShowAlertModal(false)}>Ok</Button>

                </Alert>

            </Modal>
        </>
    )

    function placeOrder(values) {
        if (deliveryCharge === "Please Choose Locality") {
            setTimeout(() => {
            setShowAlertModal(true)
            }, 1000);
        } else {
            setSubmitDisabled(true)
            setAlert(<CartOrderedModal values={values} quantity={quantity} itemTotal={(quantity * props.intPrice)} grandTotal={"₹" + (quantity * props.intPrice + parseInt(deliveryCharge.replace("₹", "")))} intPrice={props.intPrice} locality={locality} foodName={props.name} deliveryCharge={deliveryCharge} dayDate={props.dayDate} />)
        }

    }
}

function showAlert(type, content, buttonText, setAlert) {
    setAlert(<Alert show={true} variant={type} >
        {content}
        <Button variant={type} onClick={() => setAlert()}>{buttonText}</Button>
    </Alert>)
}
