import React from 'react'
import Carousel from './Carousel'
import Other from './Other'

export default function HomePage() {
  return (
    <>
      <Carousel />
      <Other />
    </>
  )
}
