import React from 'react'
import { Card, Button } from 'react-bootstrap'
import Cart from './cart/Cart'

export default function Menu(props) {

    return (
        <Card border="success" className="m-4" style={{ width: '18rem' }}>
            <Card.Img src={props.url} variant='top' />
            <Card.Body>
                <Card.Subtitle className='mb-2 text-muted'>{props.dayDate}</Card.Subtitle>
                <Card.Title>{props.name}</Card.Title>
                <span>Only at <span className='bold'>{props.price}</span> {props.unit}</span>
                <p></p>
                <Button variant="outline-success" onClick={() => props.setMenu(() => {
                    return (
                        <Cart url={props.url} name={props.name} dayDate={props.dayDate} unit={props.unit} price={props.price} intPrice={props.intPrice} maxQuantity={props.maxQuantity} />
                    );
                })}>Order Now</Button>
            </Card.Body>
        </Card>
    )
}
