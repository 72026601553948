import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

export default function Contact() {
    return (
        <div className='m-4 justify-center'>
            <h2>Have a question or doubt? Contact Us</h2>

            <hr />
            <div className='bg-info p-4'>
                <h4 className='text-white'>Have you had a look at out FAQs?</h4>
                <p className='lead text-white'>If not you can visit them <Link to="/faq" className='text-black'>here</Link></p>
            </div>

            <hr />
            <h4>You can contact us by chatting with us.</h4>
            <p className='lead'>Click the chat widget below on the right</p>
            <h4>OR</h4>
            {/* <Link to="/chat" className='btn btn-success'>Chat With Us Here</Link> */}

            <Button onClick={() => window.Tawk_API.toggle()} className='btn btn-success'>Chat With Us Here</Button>
            <p className='lead'> </p>

            <h4>Alternatively....</h4>
            <p className='lead'>You can also contact us by emailing us at <a href='mailto:care@test7777.ml'>care@test7777.ml</a></p>
        </div>
    )
}
