import { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app';
import Orders from './Orders';
import NotSignedIn from '../NotSignedIn';
import CustomProgessBar from '../../../common/CustomProgressBar';

export default function YourOrders() {

    const [ui, setUI] = useState(<CustomProgessBar />)
    // Listen to the Firebase Auth state and set the local state.

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {

            // Check if user exists
            if (user) {
                setUI(() => {
                    return <Orders />
                })
            } else {
                setUI(() => {
                    return <NotSignedIn />
                })
            }

        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    return ui
}