import React from 'react'
import { Link, NavLink } from 'react-router-dom';

export default function Footer() {

  return (
    <div className="container bg-light mw-100 flex-shrink-0 flex-column d-flex mt-5">
      <footer className="footer py-3 mt-4 md-0">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item"><Link to="/" className="nav-link px-2 text-muted">Home</Link></li>
          <li className="nav-item"><Link to="contact" className="nav-link px-2 text-muted">Contact Us</Link></li>
          <li className="nav-item"><Link to="faq" className="nav-link px-2 text-muted">FAQs</Link></li>
          <li className="nav-item"><Link to="TermsAndConditions" className="nav-link px-2 text-muted">Terms &amp; Conditions</Link></li>
          <li className="nav-item"><Link to="PrivacyPolicy" className="nav-link px-2 text-muted">Privacy Policy</Link></li>
        </ul>
        <p className="text-center text-muted">&copy; 2022 <NavLink to="/" className="text-muted">Kanha Kanhaiya</NavLink></p>
      </footer>
    </div>
  );
}