import { serverTimestamp } from "firebase/firestore";

export class Order {
    constructor(address, cookingInstructions, grandTotal, orderId, quantity, itemName, orderDate, itemTotal, deliveryCharge) {
        this.address = address;
        this.cookingInstructions = cookingInstructions;
        this.grandTotal = grandTotal;
        this.orderId = orderId;
        this.quantity = quantity;
        this.itemName = itemName;
        this.orderDate = orderDate;
        this.itemTotal = itemTotal;
        this.deliveryCharge = deliveryCharge;
    }
}

export const orderConverter = {
    toFirestore: (order) => {
        return {
            address: order.address.toString(),
            cookingInstructions: order.cookingInstructions.toString(),
            grandTotal: order.grandTotal.toString(),
            orderId: order.orderId.toString(),
            quantity: order.quantity.toString(),
            timestamp: serverTimestamp()
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Order(data.address, data.cookingInstructions, data.grandTotal, data.orderId, data.quantity);
    },
    toString: (order) => {
        return JSON.stringify(order);
    },
    fromString: (str) => {
        return JSON.parse(str);
    }
}