import React from 'react'

export default function Carousel() {
    return (
        <div className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src="https://test7777.ml/Images/1.jpg" className="d-block w-100" alt="Food Order" />
                    <h4 className="display-6 text-center py-4">Fresh</h4>
                    <h6 className="text-muted text-center px-2"><small>We use Fresh Items to prepare your amazing Breakfast</small></h6>
                </div>
                <div className="carousel-item">
                    <img src="https://test7777.ml/Images/2.jpg" className="d-block w-100" alt="Food Order" />
                    <h4 className="display-6 text-center py-4">Easy Orders</h4>
                    <h6 className="text-muted text-center px-2"><small>You can also order easily order at your fingertips by installing our app</small></h6>
                </div>
                <div className="carousel-item">
                    <img src="https://test7777.ml/Images/3.jpg" className="d-block w-100" alt="Food Order" /> <h4 className="display-6 text-center py-4">Fast Delivery</h4>
                    <h6 className="text-muted text-center px-2"><small>Your Breakfast will be delivered on correct time</small></h6>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button> <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true">
                </span>
                <span className="visually-hidden">Next</span> </button> </div>
    )
}
