import React, { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app';
import NotSignedIn from '../NotSignedIn';
import AccountDetails from './AccountDetails';
import CustomProgressBar from '../../../common/CustomProgressBar';

export default function YourAccount() {
  const [ui, setUI] = useState(<CustomProgressBar />);
    // Listen to the Firebase Auth state and set the local state.

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {

            // Check if user exists
            if (user) {
                setUI(() => {
                    return <AccountDetails />
                })
            } else {
                setUI(() => {
                    return <NotSignedIn />
                })
            }

        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    return ui
}
