import React, { useState, useEffect } from 'react'
import { Modal, ProgressBar } from 'react-bootstrap'
import * as CryptoJS from 'crypto-js'
import firebase from 'firebase/compat/app'
import { setDoc, doc } from "firebase/firestore"
import { getDatabase, ref, set, push, child } from 'firebase/database'
import { getAuth } from 'firebase/auth'
import { firestoredb } from '../../../js/firebaseSetup'
import { Order, orderConverter } from '../../../utils/Order'
import { Link } from 'react-router-dom'

export default function CartOrderedModal(props) {

    const [progress, setProgress] = useState(0)
    const [hideCloseButton, setHideCloseButton] = useState(true)

    const uid = getAuth().currentUser.uid

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                if (props.itemTotal === props.quantity * props.intPrice) {
                    if (parseInt(props.grandTotal.replace("₹", "")) === (props.itemTotal + parseInt(props.deliveryCharge.replace("₹", "")))) {
                        if (parseInt(props.grandTotal.replace("₹", "")) === ((props.intPrice * props.quantity) + parseInt(props.deliveryCharge.replace("₹", "")))) {

                            const rtdb = getDatabase();
                            const pushRef = push(child(ref(rtdb), 'FoodOrder/orderStatus/' + uid)).key;
                            const idRef = ref(rtdb, 'FoodOrder/orderStatus/' + uid + "/" + pushRef);

                            setProgress(20)
                            set(idRef, "Ordered").then(() => {
                                setProgress(40)

                                const address = props.values.address1 + (props.values.address2.trim() === "" ? "" : ", " + props.values.address2) + ", " + props.locality + ", Kanpur, Uttar Pradesh, India." + (props.values.pincode === "" ? "" : " PIN Code : " + props.values.pincode)

                                const order = new Order(address, props.values.cookingInstructions, props.grandTotal, pushRef, props.quantity, props.foodName, new Date().toLocaleDateString('en-IN'), "₹" + props.itemTotal, props.deliveryCharge);

                                setProgress(60)

                                setDoc(doc(firestoredb, props.foodName + "/" + props.dayDate + "/" + uid, pushRef).withConverter(orderConverter), order).then(() => {
                                    setProgress(80)

                                    if(localStorage.getItem("https://test7777.ml/orders?totalOrders") === undefined || localStorage.getItem("https://test7777.ml/orders?totalOrders") === null) {
                                        localStorage.setItem("https://test7777.ml/orders?id=1", CryptoJS.AES.encrypt(orderConverter.toString(order), uid).toString())
                                        localStorage.setItem("https://test7777.ml/orders?totalOrders", "1")
                                    } else {
                                        const totalOrdersTillNow = parseInt(localStorage.getItem("https://test7777.ml/orders?totalOrders"))
                                        const newID = totalOrdersTillNow + 1;
                                        localStorage.setItem("https://test7777.ml/orders?id=" + newID, CryptoJS.AES.encrypt(orderConverter.toString(order), uid).toString())
                                        localStorage.setItem("https://test7777.ml/orders?totalOrders", newID.toString())
                                    }

                                    setHideCloseButton(false)
                                    setProgress(100)
                                })
                            })
                        }
                    }
                }
            }
        }
        );
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, [props.dayDate, props.deliveryCharge, props.foodName, props.grandTotal, props.intPrice, props.itemTotal, props.locality, props.quantity, props.values, props.values.address1, props.values.address2, props.values.cookingInstructions, uid]);

    return (
        <Modal
            show={true}
            backdrop="static"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {hideCloseButton ? "Ordering" : "Ordered"} Your <h4 className='text-success'>Tasty Food</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div hidden={hideCloseButton}>
                    Congratulations! Your order has been placed successfully.
                </div>
                <ProgressBar animated now={progress} variant="success" className="m-4" label="Ordering" hidden={!hideCloseButton} />
            </Modal.Body>
            <Modal.Footer>
                <Link className='btn btn-outline-success' to="/orders" hidden={hideCloseButton}>Close</Link>
            </Modal.Footer>
        </Modal >
    )
}
