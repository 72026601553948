import React, { useEffect, useState } from 'react'
import CustomProgressBar from '../../common/CustomProgressBar';
import firebase from 'firebase/compat/app'
import AddMobileNumber from '../../login/AddMobileNumber'
import NotSignedIn from '../../login/profileManagement/NotSignedIn'
import NoMobileNumber from './NoMobileNumber'
import CartDetails from './CartDetails';

export default function CartLoggedIn(props) {

  const [UI, setUI] = useState(<CustomProgressBar />)

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (user.phoneNumber) {
          setUI(() => {return <CartDetails url={props.url} name={props.name} dayDate={props.dayDate} unit={props.unit} price={props.price} intPrice={props.intPrice} maxQuantity={props.maxQuantity} /> })
        } else setUI(<AddMobileNumber show={true} onHide={(value) => setUI(value)} closeuinomobilenumber={<NoMobileNumber />} closeuimobilenumber={<CartLoggedIn url={props.url} name={props.name} dayDate={props.dayDate} unit={props.unit} price={props.price} intPrice={props.intPrice} maxQuantity={props.maxQuantity} />}/>)
      } else setUI(<NotSignedIn />)
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [props.dayDate, props.intPrice, props.maxQuantity, props.name, props.price, props.unit, props.url]);
  
  return (
    UI
  )
}
