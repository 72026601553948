import React, { useEffect, useState } from 'react'
import { NavLink, Link } from 'react-router-dom';
import LoginModal from '../../login/LoginModal'
import firebase from 'firebase/compat/app';
import { ExistingUserGreeting, NewUserGreeting } from './Greeting';
import YourOrders from './loginActions/YourOrders';
import YourAccount from './loginActions/YourAccount';

export default function Navbar() {

    const [greeting, setGreeting] = useState("")
    const [ordersButton, setOrdersButton] = useState()
    const [accountButton, setAccountButton] = useState()
    // Listen to the Firebase Auth state and set the local state.

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {

            // Check if user exists
            if (user) {
                setGreeting(ExistingUserGreeting(user.displayName))
                setOrdersButton(YourOrders)
                setAccountButton(YourAccount)
            } else setGreeting(NewUserGreeting)

        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-white px-2">
                <Link className="navbar-brand" to="/">Food Order</Link>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavaltMarkup" aria-controls="navbarNavaltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNavaltMarkup">
                    <div className="navbar-nav">
                        <NavLink className={({ isActive }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} to="/">Home</NavLink>
                        <NavLink className={({ isActive }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} to="about">About</NavLink>
                        <NavLink className={({ isActive }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} to="blog">Blog</NavLink>
                        <NavLink className={({ isActive }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} to="areas">Areas we Deliver</NavLink>
                        <NavLink className={({ isActive }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"} to="contact">Contact Us</NavLink>
                        {ordersButton}
                        {accountButton}

                        <form className="form-inline my-2 my-lg-0">
                            <Link className="btn btn-outline-success my-2 my-sm-0" type="button" to="/order">Order Now</Link>
                        </form>
                    </div>
                </div>

                <div className="d-none d-lg-block">
                    {greeting}
                </div>
            </nav>

            <div className="modal fade" id="login_SignUpModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop='static'>
                <LoginModal />
            </div>

            <div className='d-block d-lg-none'>
                {greeting}
            </div>
        </>
    );
}



