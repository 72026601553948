import React, { useEffect, useState } from 'react'
import { ListGroup, Card, Button } from 'react-bootstrap'
import * as CryptoJS from 'crypto-js';
import { orderConverter } from '../../../../utils/Order';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';

export default function OrdersList(props) {

    const [orders, setOrders] = useState("")

    useEffect(() => {
        printOrders(props.value, setOrders, props.ViewDetails)
    }, [props.ViewDetails, props.setModal, props.value])

    return (
        <div>{orders}</div>
    )
}

function printOrders(allOrders, setOrders, ViewDetails) {

    const orders = [];

    for (let i = parseInt(allOrders); i >= 1; i--) {

        const order = localStorage.getItem("https://test7777.ml/orders?id=" + i);

        if (order !== null) {

            const bytes = CryptoJS.AES.decrypt(order, getAuth().currentUser.uid);

            const decryptedString = bytes.toString(CryptoJS.enc.Utf8)

            try {
                const json = orderConverter.fromString(decryptedString)
                const dateString = json.orderDate

                orders.push(
                    <ListGroup.Item key={i} className="m-4 p-4">

                        <Card.Title>{json.itemName}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{dateString}</Card.Subtitle>
                        <Card.Text>
                            Order ID: {json.orderId}
                        </Card.Text>
                        <Button variant="success" onClick={() => ViewDetails(json)}>View details</Button>

                    </ListGroup.Item>
                )
            } catch (error) {
                setOrders(<p className='ms-2 mh-100'>No orders yet. Please order your next breakfast and we will be happy to get it prepared for you. If you have previously ordered but it is not being shown in this page then please contact us <Link to="/contact">here</Link> in order to get the order details.</p>)
            }


        } else setOrders(<p className='ms-2 mh-100'>No orders yet. Please order your next breakfast and we will be happy to get it prepared for you. If you have previously ordered but it is not being shown in this page then please contact us <Link to="/contact">here</Link> in order to get the order details.</p>)
    }

    setOrders(<ListGroup>{orders}</ListGroup>);
}