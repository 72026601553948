import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, InputGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import { getAuth, PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from 'firebase/auth'

export default function AddMobileNumber(props) {

  const [otpSent, setOtpSent] = useState(false);
  const [closeDisabled, setCloseDisabled] = useState(false)

  const schema = yup.object().shape({
    mobileNumber: yup.string().matches("[1-9]{1}[0-9]{9}", "Invalid Mobile Number").required("Please enter your mobile number").test('len', "Please enter a valid mobile number", val => val ? val.length === 10 : false),
    otp: yup.string().when('otpSent', {
      is: true,
      then: yup.string().required(),
      otherwise: yup.string()
    })
  })

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier("addMobileNumberSubmitButton", { 'size': 'invisible' }, getAuth())
  }, [])

  const handleSendOtp = (phoneNumber) => {
    // const provider = new firebase.auth.PhoneAuthProvider()
    const provider = new PhoneAuthProvider(getAuth());
    provider.verifyPhoneNumber(phoneNumber, window.recaptchaVerifier).then((verificationID) => {
      window.confirmationResult = verificationID
      setOtpSent(true)
    })
  }

  return (
    <Modal
      {...props}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Mobile Number
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Please add your mobile number. You will have to do it only once. For any queries contact us <Link to="/contact">here</Link>.</h5>

        <Formik
          validationSchema={schema}
          onSubmit={(values, actions) => {
            if (otpSent === false) {

              const phoneNumber = "+91" + values.mobileNumber

              handleSendOtp(phoneNumber)

            } else {
              setCloseDisabled(true)
              const verificationCode = values.otp
              // window.confirmationResult.confirm(verificationCode).then(() => {
              const phoneCredential = PhoneAuthProvider.credential(window.confirmationResult, verificationCode);
              updatePhoneNumber(getAuth().currentUser, phoneCredential).then(() => {
                props.onHide(props.closeuimobilenumber)
              })
              // })
            }

            // const phoneNumber = "+91" + values.mobileNumber
            // const auth = getAuth()

            // setTimeout(() => {
            //   const appVerifier = new RecaptchaVerifier("addMobileNumberSubmitButton", { 'size': 'invisible' }, auth)
            //   const provider = new PhoneAuthProvider(auth);

            //   provider.verifyPhoneNumber(phoneNumber, appVerifier).then((verificationID) => {
            //     if (values.otp !== undefined && values.otp !== null && values.otp !== "" && values.otp !== '' && values.otp !== " ") {
            //       setCloseDisabled(true)
            //       const verificationCode = values.otp
            //       const phoneCredential = PhoneAuthProvider.credential(verificationID, verificationCode);
            //       updatePhoneNumber(auth.currentUser, phoneCredential).then(() => {
            //         props.onHide(props.closeuimobilenumber)
            //       })
            //     } else {

            //     }
            //   }).catch((error) => {
            //   })
            // }, 1000);
          }
          }
          initialValues={{
            mobileNumber: '',
            otp: ''
          }}
        >

          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (

            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Mobile Number</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text>+91</InputGroup.Text>
                  <Form.Control type="text" name="mobileNumber" value={values.mobileNumber} onChange={handleChange} isValid={touched.mobileNumber && !errors.mobileNumber} onBlur={handleBlur} placeholder="Enter mobile number" required />
                  <Form.Control.Feedback type="invalid" tooltip>Please enter a valid mobile number</Form.Control.Feedback>
                </InputGroup>

              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>OTP</Form.Label>
                <Form.Control type="password" name="otp" value={values.otp} onChange={handleChange} placeholder="OTP" isValid={touched.otp && otpSent && values.otp.trim() !== "" && values.otp.length === 6 && !errors.otp} onBlur={handleBlur} required disabled={!otpSent} />
              </Form.Group>
              <Button variant="success" id="addMobileNumberSubmitButton" type="submit" hidden={otpSent}>
                {otpSent ? "Submit" : "Send OTP"}
              </Button>
              <Button variant="success" id="addMobileNumberButton" type="submit" hidden={!otpSent}>
                {otpSent ? "Submit" : "Send OTP"}
              </Button>
            </Form>

          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          const mobileNumber = getAuth().currentUser.mobileNumber;
          setTimeout(() => {
            if (mobileNumber) {
              return props.onHide(props.closeuimobilenumber)
            } else return props.onHide(props.closeuinomobilenumber)
          }, 1000);
        }} disabled={closeDisabled}>Close</Button>
      </Modal.Footer>
    </Modal >
  )
}
