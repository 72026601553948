// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAuth } from 'firebase/auth'
import { getFirestore } from "firebase/firestore";

import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDRs_qRhdkuIbykqfTc412kE8o5JoRR6YI",
  authDomain: "food-order-7bea1.firebaseapp.com",
  projectId: "food-order-7bea1",
  storageBucket: "food-order-7bea1.appspot.com",
  messagingSenderId: "101164849768",
  appId: "1:101164849768:web:19163e94e9729057e6f9dd",
  measurementId: "G-E7R8CPS293"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase AppCheck
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdBsfwgAAAAAOTJB69pPTli90OwDhZGBb0z8GGK'),
  isTokenAutoRefreshEnabled: true
})

export const auth = getAuth(app);
export const firestoredb = getFirestore(app)
// const analytics = getAnalytics(app);
export default firebaseConfig;
