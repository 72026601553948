import './App.css';
import Navbar from './components/common/navbar/Navbar';
import Footer from './components/common/Footer';
import HomePage from './components/home/HomePage';
import About from './components/about/About';
import Areas from './components/areas_we_deliver/Areas';
import Login from './components/login/Login';
import Blog from './components/blog/Blog'
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import TermsOfService from './components/legal/TermsOfService';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import OrderNow from './components/order/OrderNow';
import YourOrders from './components/login/profileManagement/orders/YourOrders';
import YourAccount from './components/login/profileManagement/account/YourAccount';
import Contact from './components/contact/Contact';
import FAQ from './components/faqs/FAQ';

function App() {
  return (
    <Router>
        <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/areas" element={<Areas />} />
        <Route path="/auth" element={<Login />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/TermsAndConditions' element={<TermsOfService />} />
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/order' element={<OrderNow />} />
        <Route path='/orders' element={<YourOrders />} />
        <Route path='/account' element={<YourAccount />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path='*' element={<HomePage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
