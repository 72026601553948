import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app'
import CartLoggedIn from './CartLoggedIn';
import CustomProgressBar from '../../common/CustomProgressBar';
import AddMobileNumber from '../../login/AddMobileNumber';
import NoMobileNumber from './NoMobileNumber'
import NotSignedIn from '../../login/profileManagement/NotSignedIn'

export default function Cart(props) {

  const [userSignedIn, setUserSignedIn] = useState(<CustomProgressBar />);

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (user.phoneNumber) {
          setUserSignedIn(<CartLoggedIn url={props.url} name={props.name} dayDate={props.dayDate} unit={props.unit} price={props.price} intPrice={props.intPrice} maxQuantity={props.maxQuantity} />)
        } else setUserSignedIn(<AddMobileNumber show={true} onHide={(value) => setUserSignedIn(value)} closeuinomobilenumber={<NoMobileNumber />} closeuimobilenumber={() => { return <CartLoggedIn url={props.url} name={props.name} dayDate={props.dayDate} unit={props.unit} price={props.price} intPrice={props.intPrice} maxQuantity={props.maxQuantity} /> }} />)
      } else setUserSignedIn(<NotSignedIn />)
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [props.dayDate, props.intPrice, props.maxQuantity, props.name, props.price, props.unit, props.url]);

  return (
      userSignedIn
  )
}
