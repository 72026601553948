import React, { useState, useEffect } from 'react'
import CustomProgressBar from '../common/CustomProgressBar'
import Menu from './Menu'

export default function OrderNow() {

  const [menu, setMenu] = useState(<CustomProgressBar />)

  useEffect(() => {
    fetch("https://menu.test7777.ml/").then(res => {
      res.json().then(result => {
        setMenu(() => getMenu(result, setMenu))
      })
    })
  }, [])

  return (
    <>
      <h4 className='mx-4'>Click Order Now to view Cart</h4>
      {menu}
    </>
  )
}

function getMenu(result, setMenu) {
  return (
    <div className='row'>
      {result.menu.map(item => {
        const {
          url,
          name,
          dayDate,
          unit,
          price,
          intPrice,
          maxQuantity
        } = item;

        return (
          <div className='col-lg-4'>
            <Menu url={url} name={name} dayDate={dayDate} unit={unit} price={price} intPrice={intPrice} maxQuantity={maxQuantity} setMenu={(value) => setMenu(value)} />
          </div>
        )
      })}
    </div>

  )
}
