import React from 'react'
import { getAuth } from 'firebase/auth'
import { Row, Form, Col } from 'react-bootstrap'

export default function AccountDetails() {

  return (
    <div className='m-4'>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder={getAuth().currentUser.displayName} readOnly />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder={getAuth().currentUser.email} readOnly />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control type="tel" placeholder={getAuth().currentUser.phoneNumber} readOnly />
        </Form.Group>
      </Row>
    </div>
  )
}
